import React from 'react';

import '../components/layout.css';

// @ts-ignore
import logo from '../images/s4ten-logo.png';
// @ts-ignore
import hero from '../images/hero.jpg';

const IndexPage = () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="#" aria-label="Home">
                    <img
                      className="h-10 w-auto sm:h-16"
                      src={logo}
                      alt="Logo"
                    />
                  </a>
                </div>
              </div>
            </nav>
          </div>

          <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="font-heading text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                A Head Start to&nbsp;
                <span className="text-theme-red">Solving Problems</span>
              </h2>
            </div>
            <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              <p>
                Our crew have a proven track record in helping teams deliver
                high quality digital solutions. <br />
                We specialise in:
              </p>
              <ul className="m-3 list-disc">
                <li>
                  high quality technical solutions, innovation and problem
                  solving
                </li>
                <li>
                  excellence in technical culture, leadership and team dynamics
                </li>
                <li>design for customer centric products and services</li>
              </ul>
            </div>
            <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              <p>
                We focus on collaboration and integration with our clients to
                build the right thing and build it right. <br />
                Some of the ways we can help:
              </p>
              <ul className="m-3 list-disc">
                <li>need assistance tackling a thorny technical challenge</li>
                <li>mentoring / coaching, from pragmatic practices to architecture to career development</li>
              </ul>
            </div>

            <div className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              <p>
                Lets talk, if there is any way we may be able to help you, please get in
                touch{' '}
                <a
                  href="mailto:website@starter4ten.com"
                  className="underline text-theme-red"
                >
                  via email here.
                </a>
              </p>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={hero}
          alt=""
        />
      </div>
    </div>
  );
};

export default IndexPage;
